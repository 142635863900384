import { Select, SelectProps, Space } from "antd";
import "./Select.css";
import { TOption } from "../../../interfaces";
interface IProps {
  disabled?: boolean;
  loading?: boolean;
  options: TOption[];
  handleChange?: SelectProps["onChange"];
  className?: string;
  value?: string | number;
  variant?: "outlined" | "borderless" | "filled";
  handleSelect?: SelectProps["onSelect"];
  afterOnchange?: () => void;
  placeholder?: string;
  label?: string;
}
export default function SelectUI(props: IProps) {
  //! state
  const {
    disabled,
    loading,
    options,
    handleChange,
    className,
    value,
    handleSelect,
    variant,
    afterOnchange,
    label,
    ...res
  } = props;
  //! function
  const onChangeOptions = (value: any, option: any) => {
    if (afterOnchange) {
      afterOnchange();
    }
  };
  //! render
  return (
    <>
      {label && <label className="text-sm inline-block mb-1">{label}</label>}
      <Select
        onChange={handleChange}
        options={options}
        value={value}
        variant={variant}
        className={`m-w-10 ${className}`}
        onSelect={onChangeOptions}
        optionRender={(option) => <Space>{option.label}</Space>}
        {...res}
      />
    </>
  );
}
