import {
  MenuOutlined,
  PlusOutlined,
  LeftOutlined,
  AudioOutlined,
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CloudUploadOutlined,
  SendOutlined,
  SwapOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
const CommonIcons = {
  MenuOutlined: MenuOutlined,
  PlusOutlined: PlusOutlined,
  LeftOutlined: LeftOutlined,
  AudioOutlined: AudioOutlined,
  MoreOutlined: MoreOutlined,
  DeleteOutlined: DeleteOutlined,
  EditOutlined: EditOutlined,
  WarningOutlined: WarningOutlined,
  CloudUploadOutlined: CloudUploadOutlined,
  SendOutlined: SendOutlined,
  SwapOutlined: SwapOutlined,
  LoadingOutlined: LoadingOutlined,
};

export default CommonIcons;
