import { Divider } from "antd";
import { uniqueId } from "lodash";
import { useState } from "react";
import CommonIcons from "../../assets/icons";
import CommonStyles from "../../components/UI";
import { useGetConfigTop } from "../../hooks/useConfigTop/useConfigTop";
import FormConfigTop from "../../components/formConfigTop";
import { IConfigTop } from "../../interfaces/IConfigTop";
import useToggle from "../../hooks/useToggle";
import useDeleteConfigTop from "../../hooks/useConfigTop/useDeleteConfigTop";
import { showError, showSuccess } from "../../helpers/toast";
import { mesSuccess, optionsTopScore } from "../../constants";
import { getErrorMsg } from "../../helpers";

const Configs = () => {
  //! state
  const { open, shouldRender, toggle } = useToggle();
  const { mutateAsync: deleteConfig, isLoading: loadingDelete } =
    useDeleteConfigTop();
  const {
    data: dataConfigTop,
    isLoading: loadingConfigTop,
    refetch: refetchConfigTop,
  } = useGetConfigTop({
    page: 1,
    items_per_page: 100,
  });
  const [add, setAdd] = useState(false);
  const [itemUpdate, setItemUpdate] = useState<null | IConfigTop>(null);

  //! function
  const addAction = () => {
    setAdd(!add);
    setItemUpdate(null);
  };
  const handleUpdate = (item: IConfigTop) => {
    setItemUpdate(item);
    setAdd(!add);
  };
  const handleDelete = async (item: IConfigTop) => {
    setItemUpdate(item);
    toggle();
  };
  const onConfirmDelete = async () => {
    try {
      await deleteConfig(Number(itemUpdate?.id), {
        onSuccess: (res) => {
          setItemUpdate(null);
          toggle();
          refetchConfigTop();
          showSuccess(res.data?.message ?? mesSuccess);
        },
      });
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  //! render
  const renderConfigTop = () => {
    return dataConfigTop?.data?.data?.map((item: IConfigTop, index: number) => {
      const itemFind = optionsTopScore.find(
        (itemScore) =>
          itemScore.value === `${item.top_score_min},${item.top_score_max}`
      );
      return (
        <div
          className="flex items-start p-3"
          style={{ background: "rgba(235, 237, 238, 1)" }}
          key={index}
        >
          <div
            className="flex flex-col w-[90%] gap-2 rounded-sm cursor-pointer"
            onClick={() => {
              // handleCard(item);
            }}
          >
            <CommonStyles.TypographyUI className="text-lg">
              {item?.title}
            </CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI>
              Loại máy bay: {itemFind?.label}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI>
              Giá trị top nhỏ nhất: {item?.top_score_min}
            </CommonStyles.TypographyUI>

            <CommonStyles.TypographyUI>
              Giá trị top lớn nhất: {item?.top_score_max}
            </CommonStyles.TypographyUI>

            <div className="flex items-center gap-1">
              Màu chủ đạo:
              <div
                className={`inline-block h-3 w-12 rounded-lg`}
                style={{
                  background: item?.color,
                }}
              ></div>
            </div>
          </div>

          <div className="flex justify-end">
            <CommonStyles.ButtomAction
              actionDelete={() => {
                handleDelete(item);
              }}
              actionUpdate={() => {
                handleUpdate(item);
              }}
            />
          </div>
        </div>
      );
    });
  };
  const renderConfig = () => {
    if (loadingConfigTop) {
      return <CommonStyles.LoadingUI />;
    }
    if (add) {
      return (
        <FormConfigTop
          handleClose={() => {
            setAdd(!add);
          }}
          itemUpdate={itemUpdate}
          refetchConfigTop={refetchConfigTop}
        />
      );
    }
    return (
      <div className="col-span-1 border-r  ">
        <div className="flex lg:flex-row lg:justify-between lg:items-center flex-col items-start px-3  mt-3">
          <CommonStyles.TypographyUI className="font-bold text-lg">
            Cấu hình
          </CommonStyles.TypographyUI>
          <CommonStyles.ButtonUI
            className="bg-black !hover:bg-black text-md "
            Icons={<CommonIcons.PlusOutlined />}
            onClick={addAction}
          >
            Thêm mới
          </CommonStyles.ButtonUI>
        </div>

        <Divider className="m-2" />

        <div
          className="flex flex-col gap-y-2 mx-2 h-[87vh]"
          style={{ overflowY: "scroll" }}
        >
          {renderConfigTop()}
        </div>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-3 gap-2 h-full">
      <div>{renderConfig()}</div>
      <div className="col-span-2 mt-2">
        <div className="flex flex-col h-full gap-3">
          <div className={"w-full border rounded-lg h-full"}>
            <CommonStyles.LeafletUI optionLayer uniqueId={uniqueId()} />
          </div>
        </div>
      </div>
      {shouldRender && (
        <CommonStyles.ModalUI
          type="error"
          toggle={toggle}
          open={open}
          onConfirm={onConfirmDelete}
        />
      )}
    </div>
  );
};

export default Configs;
