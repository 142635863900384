import InputUI from "./InputUI/InputUI";
import ButtonUI from "./Button/ButtonUI";
import CardUI from "./Card/CardUI";
import FormFieldUI from "./FormField/FormFieldUI";
import LeafletUI from "./Leaflet/LeafletUI";
import TextAreaUI from "./TextArea/TextAreaUI";
import TypographyUI from "./Typography/TypographyUI";
import SelectUI from "./SelectUI/SelectUI";
import FloatButtonUI from "./FloatButtonUI/FloatButtonUI";
import TooltipUI from "./TooltipUI/TooltipUI";
import ModalUI from "./ModalUI/ModalUI";
import ButtonAction from "./ButtonAction/ButtonAction";
import PopoverUI from "./PopoverUI/PopoverUI";
import LoadingUI from "./LoadingUI/LoadingUI";
import FormAntdDebugs from "./FormAntdDebugs";

const CommonStyles = {
  ButtonUI: ButtonUI,
  TypographyUI: TypographyUI,
  CardUI: CardUI,
  LeafletUI: LeafletUI,
  TextArea: TextAreaUI,
  FormFieldUI: FormFieldUI,
  InputUI: InputUI,
  SelectUI: SelectUI,
  FloatButtonUI: FloatButtonUI,
  TooltipUI: TooltipUI,
  ModalUI: ModalUI,
  ButtomAction: ButtonAction,
  PopoverUI: PopoverUI,
  LoadingUI: LoadingUI,
  FormAntdDebugs: FormAntdDebugs,
};

export default CommonStyles;
