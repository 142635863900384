import { Tooltip } from "antd";
import React, { ReactNode } from "react";
interface IProps {
  title: string | React.ReactNode;
  color?: string;
  children: ReactNode;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
}
export default function TooltipUI(props: IProps) {
  //! state
  const { title, children, placement, color, ...res } = props;
  return (
    <Tooltip
      title={title}
      zIndex={3000}
      placement={placement}
      color={color ?? "#bdbdbd"}
      {...res}
    >
      {children}
    </Tooltip>
  );
}
