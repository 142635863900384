import { ColorPicker, Divider, Form, InputNumber } from "antd";
import { mesSuccess, optionsTopScore } from "../../constants";
import { getErrorMsg } from "../../helpers";
import { showError, showSuccess } from "../../helpers/toast";
import usePostConfigTop from "../../hooks/useConfigTop/usePostConfigTop";
import useUpdateConfigTop from "../../hooks/useConfigTop/useUpdateConfigTop";
import { IConfigTop } from "../../interfaces/IConfigTop";
import CommonStyles from "../UI";
interface IProps {
  itemUpdate: IConfigTop | null;
  refetchConfigTop?: any;
  handleClose?: () => void;
}
const FormConfigTop = (props: IProps) => {
  //! state
  const { itemUpdate, refetchConfigTop, handleClose } = props;
  const [form] = Form.useForm();
  const { mutateAsync: postConfigTop, isLoading: loadingPost } =
    usePostConfigTop();
  const { mutateAsync: updateConfigTop, isLoading: loadingUpdateConfigTop } =
    useUpdateConfigTop();

  const initialValues = {
    title: itemUpdate?.title ? itemUpdate?.title : "",
    top_score_max: itemUpdate?.top_score_max ? itemUpdate?.top_score_max : null,
    top_score_min: itemUpdate?.top_score_min ? itemUpdate?.top_score_min : null,
    plantType: itemUpdate?.top_score_min
      ? `${itemUpdate?.top_score_min},${itemUpdate?.top_score_max}`
      : null,
    color: itemUpdate?.color ? itemUpdate?.color : `#1677ff`,
  };
  //! function
  const handleSubmit = async (values: IConfigTop) => {
    try {
      const valueScore = values?.plantType?.split(",");
      const itemSend = {
        title: values?.title,
        top_score_max: valueScore[1],
        top_score_min: valueScore[0],
        color: values?.color,
      };
      if (!itemUpdate) {
        await postConfigTop(itemSend, {
          onSuccess: () => {
            refetchConfigTop && refetchConfigTop();

            showSuccess(mesSuccess);
            handleClose && handleClose();
          },
        });
      } else {
        await updateConfigTop(
          { id: Number(itemUpdate?.id), body: itemSend },
          {
            onSuccess: (res) => {
              refetchConfigTop && refetchConfigTop();

              showSuccess(res.data?.message ?? mesSuccess);
              handleClose && handleClose();
            },
          }
        );
      }
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  //! effect
  //! render
  return (
    <div className="col-span-1 border-r px-3 mt-3">
      <div className="flex justify-between">
        <CommonStyles.TypographyUI className="font-bold text-lg">
          {itemUpdate ? "Thông tin cấu hình" : "Thêm mới cấu hình"}
        </CommonStyles.TypographyUI>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-2">
        <Form
          name="temp"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
        >
          <CommonStyles.FormFieldUI
            name="title"
            label="Tiêu đề"
            rules={[{ required: true, message: "Tiều đề không được trống!" }]}
            component={
              <CommonStyles.InputUI
                className="bg-gray-100"
                maxLength={50}
                placeholder={"Nhập tiêu đề"}
                variant="filled"
              />
            }
          />
          <div className="w-full">
            <CommonStyles.FormFieldUI
              name="plantType"
              rules={[
                { required: true, message: "Loại máy bay không được trống!" },
              ]}
              component={
                <CommonStyles.SelectUI
                  label="Loại máy bay"
                  variant="filled"
                  className="w-full bg-gray-50"
                  placeholder="Loại máy bay"
                  options={optionsTopScore}
                />
              }
            />
          </div>
          {/* <div className="grid grid-cols-2 gap-4 mt-5">
            <CommonStyles.FormFieldUI
              name="top_score_min"
              label="Giá trị top nhỏ nhất"
              component={
                <InputNumber className="w-full bg-gray-100" disabled />
              }
            />

            <CommonStyles.FormFieldUI
              name="top_score_max"
              label="Giá trị top lớn nhất"
              component={
                <InputNumber className="w-full bg-gray-100" disabled />
              }
            />
          </div> */}

          <CommonStyles.FormFieldUI
            label="Chọn màu"
            name="color"
            component={
              <ColorPicker
                showText
                allowClear
                format="hex"
                defaultFormat="hex"
                onChangeComplete={(color) => {
                  form.setFieldValue("color", color.toHexString());
                }}
              />
            }
          />

          {/* <CommonStyles.FormFieldUI
            name="description"
            rules={[{ required: true, message: "Mô tả không được để trống!" }]}
            label="Ghi chú"
            component={
              <CommonStyles.TextArea
                rows={7}
                maxLength={50}
                placeholder={"Nhập nội dung"}
                className="bg-gray-100"
              />
            }
          /> */}
          <div className=" flex justify-end gap-3 pt-4">
            <CommonStyles.ButtonUI
              className="bg-gray-100 text-black px-10"
              onClick={() => {
                handleClose && handleClose();
              }}
            >
              Hủy
            </CommonStyles.ButtonUI>

            <CommonStyles.ButtonUI
              action="submit"
              className="bg-black text-white px-10"
              loading={loadingPost || loadingUpdateConfigTop}
            >
              Lưu
            </CommonStyles.ButtonUI>
          </div>
          <CommonStyles.FormAntdDebugs />
        </Form>
      </div>
    </div>
  );
};

export default FormConfigTop;
