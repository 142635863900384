import {
  FeatureGroup,
  GeoJSON,
  LayersControl,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
  useMap,
} from "react-leaflet";
import * as turf from "@turf/turf";
import L, { LatLngExpression } from "leaflet";
import _ from "lodash";
import { useEffect, useState, useRef } from "react";
import { urlMAP } from "../../../constants/api";
import "./leaflet.css";

interface IProps {
  maxZoom?: number;
  minZoom?: number;
  zoom?: number;
  zoomControl?: boolean;
  className?: string;
  dataGeoJson?: any;
  optionLayer?: boolean;
  polylines?: any;
  markers?: { latitude: number; longitude: number }[];
  renderPopupMarkers?: any;
  uniqueId?: string;
  defaultAllJSON?: boolean;
  hasWMS?: boolean;
  defaultHiddenSquare?: boolean;
  positionCenter?: LatLngExpression;
  children?: any;
}
export default function LeafletUI(props: IProps) {
  //! state
  const {
    zoom = 5,
    maxZoom = 20,
    minZoom = 4,
    zoomControl = true,
    dataGeoJson,
    optionLayer,
    polylines,
    renderPopupMarkers,
    uniqueId,
    defaultAllJSON,
    hasWMS,
    defaultHiddenSquare,
    positionCenter,
    children,
  } = props;

  const [curved, setCurved] = useState<Array<any> | any>([]);
  const [markers, setMarkers] = useState<Array<any>>([]);

  const position: LatLngExpression = positionCenter ?? [
    17.516525710679844, 106.68387626810897,
  ];
  //! function
  //! render

  const onEachFeature = (feature: any, layer: any) => {
    if (feature.properties && feature.properties.id) {
      // Tính trung tâm của polygon
      const center = layer.getBounds().getCenter();
      layer
        .bindTooltip(feature.properties.id, {
          permanent: true,
          direction: "center",
          className: "layer-custom",
        })
        .openTooltip(center);
    }
  };
  const onEachFeatureLarge = (feature: any, layer: any) => {
    if (feature.properties && feature.properties.id) {
      // Tính trung tâm của polygon
      const center = layer.getBounds().getCenter();
      layer
        .bindTooltip(feature.properties.id, {
          permanent: true,
          direction: "center",
          className: "layer-custom-large",
        })
        .openTooltip(center);
    }
  };
  let dataPolylines: any = [];

  polylines?.forEach((element: any) => {
    const children = element?.data_score;
    let arrLatLon: any = [];

    for (let index = 0; index < children?.length; index++) {
      if (
        children[index]?.path_symbol === "circled around" &&
        children[index + 1]?.path_symbol === "circled around"
      ) {
      } else {
        arrLatLon.push([
          Number(children[index]?.latitude),
          Number(children[index]?.longitude),
        ]);

        if (index !== children.length - 1) {
          arrLatLon.push([
            Number(children[index + 1]?.latitude),
            Number(children[index + 1]?.longitude),
          ]);
        }
      }
    }

    dataPolylines.push(arrLatLon);
  });

  useEffect(() => {
    if (polylines) {
      setMarkers([]);
      let arrayMarkers: Array<any> = [];
      let arrayCurve: Array<any> = [];
      polylines?.map((item: any) => {
        filterData(item, arrayMarkers, arrayCurve);
      });
    }
  }, [polylines]);

  const filterData = (data: any, arrayMarkers: any, arrayCurve: any) => {
    let lineturf = [];
    let locations = data?.data_score || [];
    for (let i = 1; i < locations.length; i++) {
      if (locations[i - 1]?.path_symbol === "circled around") {
        lineturf.push([locations[i - 1].longitude, locations[i - 1].latitude]);
      }
      if (
        locations[i]?.path_symbol === "circled around" &&
        locations.length - 1 === i
      ) {
        lineturf.push([locations[i].longitude, locations[i].latitude]);
      }
      let angle = calculateAzimuth(
        { x: locations[i - 1].longitude, y: locations[i - 1].latitude },
        { x: locations[i].longitude, y: locations[i].latitude },
        "EPSG:4326"
      );
      let icon = null;
      switch (locations[i]?.path_symbol) {
        case "direct fly":
          icon = createMarkerIcon("direct fly", angle);
          break;

        case "lost sight":
          icon = createMarkerIcon("lost sight", angle);
          break;

        case "temporarily lost target":
          icon = createMarkerIcon("temporarily lost target", angle);
          break;

        default:
          icon = createMarkerIcon("", angle);
          break;
      }
      arrayMarkers.push({
        icon: icon,
        position: [locations[i].latitude, locations[i].longitude],
      });
    }
    setMarkers(arrayMarkers);

    if (lineturf.length > 0) {
      const line = turf.lineString(lineturf);
      const c = turf.bezierSpline(line);
      arrayCurve.push(c);
      setCurved(arrayCurve);
    } else {
      setCurved([]);
    }
  };

  const createMarkerIcon = (type: string, angle: number) => {
    let html = getHtmlIcon(type, angle);

    return L.divIcon({
      className: "custom-icon-marker",
      iconSize: L.point(64, 64),
      html: html,
      iconAnchor: [32, 32],
      popupAnchor: [0, 0],
    });
  };

  const getHtmlIcon = (type: string, angle: number) => {
    let html = ``;
    switch (type) {
      case "direct fly":
        html = `<svg style="transform: rotate(${angle}deg); width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_72_11)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.321602 64.2624C-0.00724232 63.9404 -0.0127961 63.4128 0.309197 63.084L32.3081 33.0825C32.4126 32.9758 32.5939 33.0497 32.5939 33.1991V65.054C32.5939 65.5143 32.2207 65.8874 31.7605 65.8874C31.3003 65.8874 30.9271 65.5143 30.9271 65.054L30.9271 36.875L1.50005 64.25C1.17805 64.5788 0.650446 64.5844 0.321602 64.2624Z" fill="${polylines?.[0]?.color?.color}"/>
                </g>
                <defs>
                <clipPath id="clip0_72_11">
                <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
            </svg>`;
        break;
      case "temporarily lost target":
        html = `<svg style="transform: rotate(${angle}deg); width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_72_2)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.251062 64.2614C-0.0787663 63.9384 -0.0843368 63.4092 0.23862 63.0794L31.2467 34.1722H20.1543C19.6927 34.1722 19.3183 33.798 19.3183 33.3364C19.3183 32.8748 19.6927 32.5006 20.1543 32.5006H43.662C44.1236 32.5006 44.4978 32.8748 44.4978 33.3364C44.4978 33.798 44.1236 34.1722 43.662 34.1722H32.5873V64.508C32.5873 64.9696 32.2131 65.3438 31.7515 65.3438C31.2899 65.3438 30.9157 64.9696 30.9157 64.508V36.8996L1.43303 64.2489C1.11008 64.5788 0.58089 64.5843 0.251062 64.2614Z" fill="${polylines?.[0]?.color?.color}"/>
                </g>
                <defs>
                <clipPath id="clip0_72_2">
                <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
            </svg>`;
        break;
      case "lost sight":
        html = `<svg style="transform: rotate(${angle}deg); width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_72_4)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.251062 64.2614C-0.0787663 63.9384 -0.0843368 63.4092 0.23862 63.0794L31.2467 34.1722H20.1543C19.6927 34.1722 19.3183 33.798 19.3183 33.3364C19.3183 32.8748 19.6927 32.5006 20.1543 32.5006H43.662C44.1236 32.5006 44.4978 32.8748 44.4978 33.3364C44.4978 33.798 44.1236 34.1722 43.662 34.1722H32.5873V64.5079C32.5873 64.9696 32.2131 65.3437 31.7515 65.3437C31.2899 65.3437 30.9157 64.9696 30.9157 64.5079V36.8996L1.43303 64.2489C1.11008 64.5788 0.58089 64.5843 0.251062 64.2614Z" fill="${polylines?.[0]?.color?.color}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3156 28.0937C19.3156 27.6381 19.685 27.2687 20.1406 27.2687H43.6875C44.1431 27.2687 44.5125 27.6381 44.5125 28.0937C44.5125 28.5494 44.1431 28.9187 43.6875 28.9187H20.1406C19.685 28.9187 19.3156 28.5494 19.3156 28.0937Z" fill="${polylines?.[0]?.color?.color}"/>
                </g>
                <defs>
                <clipPath id="clip0_72_4">
                <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
            </svg>`;
        break;
    }
    return html;
  };

  const calculateAzimuth = function (p1: any, p2: any, pj: any) {
    var p1proj = p1;
    var p2proj = p2;
    var lon1 = (p1proj.x * Math.PI) / 180.0;
    var lat1 = (p1proj.y * Math.PI) / 180.0;
    var lon2 = (p2proj.x * Math.PI) / 180.0;
    var lat2 = (p2proj.y * Math.PI) / 180.0;
    var dLon = lon2 - lon1;
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

    var azimuth = ((Math.atan2(y, x) * 180.0) / Math.PI + 360) % 360;

    return azimuth;
  };
  const renderCurve = () => {
    if (curved.length === 0) return null;

    return curved?.map((item: any, index: number) => {
      return (
        <GeoJSON
          key={Math.random()}
          data={item}
          style={{
            color: polylines?.[0]?.color?.color ?? "#000000",
            opacity: 1,
          }}
        />
      );
    });
  };

  useEffect(() => {}, [position]);
  return (
    <MapContainer
      id="map"
      key={uniqueId && uniqueId}
      center={position}
      zoom={zoom}
      maxZoom={maxZoom}
      minZoom={minZoom}
      zoomControl={false}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      attributionControl={false}
      style={{
        width: "100%",
        height: "100%",
        minHeight: 600,
        marginBottom: "12px",
      }}
    >
      <TileLayer url="https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}" />
      {zoomControl && <ZoomControl position="bottomleft" />}
      {!_.isEmpty(polylines) && renderPopupMarkers}
      {curved.length > 0 && renderCurve()}

      {!_.isEmpty(polylines) && (
        <Polyline
          // pathOptions={limeOptions}
          positions={dataPolylines}
          color={polylines?.[0]?.color?.color ?? "black"}
        />
      )}
      {markers.map((marker, index) => {
        return (
          <Marker
            key={index}
            icon={marker?.icon}
            position={marker.position}
          ></Marker>
        );
      })}
      {optionLayer && (
        <LayersControl position="topright">
          <LayersControl.Overlay
            checked={!hasWMS && !defaultHiddenSquare}
            name="Ô vuông cơ bản"
          >
            <FeatureGroup>
              {!_.isEmpty(dataGeoJson?.basicSquare) && (
                <GeoJSON
                  data={dataGeoJson.basicSquare}
                  style={{ color: "#e53935", opacity: 0.5 }}
                  onEachFeature={onEachFeature}
                />
              )}
            </FeatureGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay
            checked={!hasWMS && !defaultHiddenSquare}
            name="Ô vuông lớn"
          >
            <FeatureGroup>
              {!_.isEmpty(dataGeoJson?.largeSquare) && (
                <GeoJSON
                  data={dataGeoJson.largeSquare}
                  onEachFeature={onEachFeatureLarge}
                />
              )}
            </FeatureGroup>
          </LayersControl.Overlay>
          {hasWMS && (
            <LayersControl.Overlay
              checked={false}
              name="Ô vuông vừa và tưởng tượng"
            >
              <FeatureGroup>
                {
                  <WMSTileLayer
                    layers="khong-quan:medium_imaginary_layer"
                    url={`${urlMAP}`}
                    transparent={true}
                    format="image/png"
                    minZoom={10}
                    maxZoom={18}
                    opacity={0.3}
                  />
                }
              </FeatureGroup>
            </LayersControl.Overlay>
          )}
        </LayersControl>
      )}

      {children}
    </MapContainer>
  );
}
