import { useMutation } from "react-query";
import templateService from "../../services/templateService";
import configTopService from "../../services/configTopService";

interface IUpdate {
  id: number;
  body: {
    title: string;
    top_score_min: any;
    top_score_max: any;
    color: string;
  };
}

export const useUpdateConfigTop = () => {
  return useMutation({
    mutationFn: ({ id, body }: IUpdate) =>
      configTopService.updateConfigTop(id, body),
  });
};

export default useUpdateConfigTop;
