import { Collapse, CollapseProps, Drawer, FloatButton, Space } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import { useReactMediaRecorder } from "react-media-recorder-2";
import { useParams, useSearchParams } from "react-router-dom";
import CommonIcons from "../../../assets/icons";
import { urlsAPI } from "../../../constants/api";
import { getErrorMsg, scrollToLastMessage } from "../../../helpers";
import { showError, showSuccess } from "../../../helpers/toast";
import useSpeech2Text from "../../../hooks/useSpeech2Text/useSpeech2Text";
import useToggle from "../../../hooks/useToggle";
import chatService from "../../../services/chatService";
import CommonStyles from "../../UI";
import FloatButtonUI from "../../UI/FloatButtonUI/FloatButtonUI";
import { EStatusRecorder } from "../../../constants";

export default function Chatbot({
  detailPlan,
  template,
  setPolylines,
  polylines,
}: {
  detailPlan: any;
  template: any;
  setPolylines: any;
  polylines: any;
}) {
  const [valueDetect, setValueDetect] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { open, shouldRender, toggle } = useToggle();
  const {
    open: openDrawer,
    shouldRender: shouldRenderDrawer,
    toggle: toggleDrawer,
  } = useToggle();
  // const { mutateAsync: chat, isLoading: loadingChat } = useChatList();
  const [keyPressed, setKeyPressed] = useState("");
  const { mutateAsync: speechMutate, isLoading: loadingS2T } = useSpeech2Text();
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    onStop: async (blobUrl, blob) => {
      handleStopMic(blob);
    },
  });

  const [loading, setLoading] = useState(false);
  //! function

  const handleMic = async () => {
    // if (!loadingChat) {
    //   startRecording();
    //   toggle();
    // }
    if (!loading) {
      startRecording();
      toggle();
    }
  };

  const handleStopMic = async (blob: Blob) => {
    try {
      const formData = new FormData();
      const audioFile = new File([blob], "voice.wav", {
        type: "audio/wav",
      });
      formData.append("audio", audioFile);
      const res = await speechMutate(formData);

      setValueDetect(res?.data?.transcription);
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  const handleUploadFile = async (event: any) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const audioFile = event.target.files[0];
      formData.append("audio", audioFile);
      const res = await speechMutate(formData);
      setValueDetect(res?.data?.transcription);
      toggle();
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      setLoading(false);
    }
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <div className="text-base">Thông tin</div>,
      children: (
        <InfomationFlightPlan detailPlan={detailPlan} template={template} />
      ),
    },
    {
      key: "2",
      label: <div className="text-base">Lịch trình bay</div>,
      children: (
        <ListFlightPlan
          detailPlan={detailPlan}
          setPolylines={setPolylines}
          toggleDrawer={toggleDrawer}
          polylines={polylines}
        />
      ),
    },
  ];

  //! effect
  useEffect(() => {
    scrollToLastMessage(messagesEndRef);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      setKeyPressed(event.key);
    };

    const handleKeyUp = () => {
      stopRecording();
      setKeyPressed("");
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (keyPressed === "Shift" && !loading) {
      handleMic();
    }
  }, [keyPressed]);

  //! render
  const renderStatusRecorder = () => {
    switch (status as any) {
      case EStatusRecorder.Recording:
        return "Đang thu âm";
      case EStatusRecorder.Stopped:
        return "Đã dừng";
      case EStatusRecorder.Stopping:
        return "Đang dừng";
      case EStatusRecorder.Paused:
        return "Đang tạm dừng";
      default:
        return "Không xác định";
    }
  };

  const renderColorStatus = () => {
    switch (status as any) {
      case EStatusRecorder.Recording:
        return "bg-green-500";
      case EStatusRecorder.Stopped:
        return "bg-red-500";
      case EStatusRecorder.Stopping:
        return "bg-yellow-500";
      case EStatusRecorder.Paused:
        return "bg-blue-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <>
      <FloatButton.Group shape="circle" style={{ insetInlineEnd: 24 }}>
        <FloatButtonUI
          icon={<CommonIcons.MenuOutlined />}
          tooltip="Thông tin"
          onClick={toggleDrawer}
        />
        <FloatButtonUI
          icon={<CommonIcons.AudioOutlined />}
          onClick={() => handleMic()}
          tooltip="Giữ phim Shift để thu âm"
        />

        <FloatButtonUI
          icon={
            <div className="cursor-pointer">
              <label htmlFor="file-upload" className="cursor-pointer">
                {loading ? (
                  <CommonIcons.LoadingOutlined />
                ) : (
                  <CommonIcons.CloudUploadOutlined />
                )}
              </label>
              <input
                type="file"
                id="file-upload"
                onChange={handleUploadFile}
                className="hidden"
              />
            </div>
          }
          tooltip="Upload file audio"
        />
      </FloatButton.Group>

      {/* <p>Phím đang giữ: {keyPressed}</p> */}

      {shouldRender && (
        <CommonStyles.ModalUI
          toggle={toggle}
          open={open}
          hiddenAction
          height={600}
          maskClosable={false}
        >
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-center gap-2 text-base font-medium">
              <div
                className={`size-3 rounded-full ${renderColorStatus()}`}
              ></div>
              {renderStatusRecorder()}
            </div>

            <Space className="flex justify-center">
              {/* <CommonStyles.ButtonUI
                onClick={() => {
                  status === EStatusRecorder.Paused
                    ? resumeRecording()
                    : pauseRecording();
                }}
                ghost
                disabled={status !== EStatusRecorder.Paused}
              >
                {status === EStatusRecorder.Paused ? "Tiếp tục" : "Tạm dừng"}
              </CommonStyles.ButtonUI> */}
              <CommonStyles.ButtonUI
                onClick={() => {
                  stopRecording();
                }}
                ghost
                danger
                disabled={status === EStatusRecorder.Stopped}
              >
                Dừng ghi
              </CommonStyles.ButtonUI>
            </Space>
          </div>

          <TableDataVoice valueDetect={valueDetect} toggleModal={toggle} />
        </CommonStyles.ModalUI>
      )}

      {shouldRenderDrawer && (
        <Drawer
          title="Thông tin tiêu đồ"
          onClose={toggleDrawer}
          open={openDrawer}
        >
          <Collapse
            items={items}
            defaultActiveKey={["2"]}
            expandIconPosition="end"
            className="bg-white"
            bordered={false}
          />
        </Drawer>
      )}
    </>
  );
}

const TableDataVoice = ({
  valueDetect,
  toggleModal,
}: {
  valueDetect: string;
  toggleModal: any;
}) => {
  //! state
  const map = useMap();
  const parmas = useParams();
  const { open, shouldRender, toggle } = useToggle();
  const [indexDelete, setIndexDelete] = useState<number>();
  const [dataSource, setDataSource] = useState<any>([]);
  const [textEdit, setTextEdit] = useState<string>(valueDetect);
  const [dataChecked, setDataChecked] = useState<any>([]);
  const [loadingSendData, setLoadingSendData] = useState<boolean>(false);
  //! function
  const handleCheckFormatData = async () => {
    try {
      const response = await axios.get(
        `${urlsAPI}/chat_format?data=${textEdit}`
      );
      if (response.status === 200) {
        setDataChecked(response.data.result[0]);
      }
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  const handleDeleteValue = (index: number) => {
    setIndexDelete(index);
    toggle();
  };
  const confirmDelete = () => {
    const newDataSource = [...dataSource];
    newDataSource.splice(Number(indexDelete), 1);
    setDataSource(newDataSource);
    toggle();
  };
  const handleSendData = async () => {
    try {
      setLoadingSendData(true);
      const topScore = dataChecked[0].slice(0, 3);
      const response = await chatService.chatList(dataChecked, {
        airforce_id: parmas?.id,
      });
      if (response.status === 200) {
        showSuccess("Thành công");
        const routeMatch = response.data?.data?.route?.find(
          (item: any) => item?.top_score == topScore
        );
        if (routeMatch) {
          map.flyTo(
            [
              routeMatch?.data_score[0]?.latitude,
              routeMatch?.data_score[0]?.longitude,
            ],
            9
          );
        }
        toggleModal();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      setLoadingSendData(false);
    }
  };
  //! effect
  useEffect(() => {
    setTextEdit(valueDetect);
  }, [valueDetect]);

  //! render
  const renderDataTransfer = () => {
    return dataChecked?.map((item: string, index: number) => {
      return <p>{item}</p>;
    });
  };

  if (loadingSendData) {
    return <CommonStyles.LoadingUI />;
  }

  return (
    <div>
      <div className="flex gap-3">
        <div className="basis-1/2 ">
          <p className="text-lg font-semibold text-center">AI nhận diện</p>

          <div className="h-[400px]">
            <CommonStyles.TextArea
              defaultValue={textEdit}
              value={textEdit}
              onChange={(e) => setTextEdit(e.target.value)}
              autoSize
              className="!h-[400px]"
            />
          </div>
        </div>

        <div className="basis-1/2">
          <p className="text-lg font-semibold text-center">Dữ liệu</p>
          <div className="border rounded-md h-[400px] overflow-y-scroll px-2 py-1">
            {renderDataTransfer()}
          </div>
        </div>
      </div>

      <Space className="flex justify-center mt-5">
        <CommonStyles.ButtonUI ghost onClick={handleCheckFormatData}>
          Kiểm tra dữ liệu
        </CommonStyles.ButtonUI>
        <CommonStyles.ButtonUI
          onClick={handleSendData}
          disabled={dataChecked?.length <= 0}
        >
          Gửi dữ liệu
        </CommonStyles.ButtonUI>
      </Space>

      {shouldRender && (
        <CommonStyles.ModalUI
          title="Bạn có muốn xoá dữ liệu AI nhận diện"
          toggle={toggle}
          open={open}
          type="error"
          onConfirm={confirmDelete}
        />
      )}
    </div>
  );
};

const InfomationFlightPlan = ({
  detailPlan,
  template,
}: {
  detailPlan: any;
  template: any;
}) => {
  return (
    <div className={`flex flex-col gap-2`}>
      <CommonStyles.TypographyUI className="text-base ">
        {detailPlan?.title}
      </CommonStyles.TypographyUI>
      <CommonStyles.TypographyUI>
        Mẫu sử dụng: {template?.title}
      </CommonStyles.TypographyUI>
      <CommonStyles.TypographyUI>{`Ngày tạo: ${moment(
        detailPlan?.created_at
      ).format("DD/MM/YYYY")}`}</CommonStyles.TypographyUI>
      <CommonStyles.TypographyUI>{`Ghi chú: ${detailPlan?.description}`}</CommonStyles.TypographyUI>
    </div>
  );
};

const ListFlightPlan = ({
  detailPlan,
  setPolylines,
  polylines,
  toggleDrawer,
}: {
  detailPlan: any;
  setPolylines: any;
  polylines: any;
  toggleDrawer: any;
}) => {
  //! state
  const map = useMap();
  const [searchParams, setSearchParams] = useSearchParams();
  const top_score = searchParams.get("top_score");
  const [valueDistance, setValueDistance] = useState<{
    start: {
      point: string;
      data: any;
    };
    end: {
      point: string;
      data: any;
    };
  }>();
  const [distance, setDistance] = useState<number>(0);
  //! function
  const handleSetPolines = (route: any) => {
    toggleDrawer();
    setPolylines([route]);
    setSearchParams({
      ...searchParams,
      top_score: route?.top_score,
    });
    map.flyTo(
      [route?.data_score[0].latitude, route?.data_score[0].longitude],
      9
    );
  };
  const handleSetDistance = (value: any) => {
    let key = "start";
    if (valueDistance?.start.point) {
      key = "end";
    }
    setValueDistance((prev: any) => ({
      ...prev,
      [key]: {
        point: `${value.point + 1}`,
        data: value.data,
      },
    }));
  };
  const handleCalculateDistance = () => {
    if (valueDistance?.start?.data && valueDistance?.end?.data) {
      const distance = map.distance(
        [
          valueDistance?.start?.data?.latitude,
          valueDistance?.start?.data?.longitude,
        ],
        [
          valueDistance?.end?.data?.latitude,
          valueDistance?.end?.data?.longitude,
        ]
      );
      const distanceKM = (distance / 1000).toFixed(2);
      setDistance(Number(distanceKM));
      // return `Khoảng cách: ${distance} km`;
    } else {
      // return "Vui lòng chọn điểm bắt đầu và điểm kết thúc";
    }
  };
  //! render
  const renderFlightPlan = () => {
    return detailPlan?.route?.map((item: any) => {
      return (
        <div
          className={`${
            top_score === item?.top_score ? "underline text-blue-500" : ""
          }`}
          onClick={() => {
            handleSetPolines(item);
          }}
        >
          Mã chỉ số top: {item?.top_score}
        </div>
      );
    });
  };

  const renderLocationFlight = () => {
    return polylines?.[0]?.data_score?.map((item: any, index: any) => {
      return (
        <div
          key={index}
          onClick={() => handleSetDistance({ point: index, data: item })}
          className={`${
            index + 1 == valueDistance?.start?.point ||
            index + 1 == valueDistance?.end?.point
              ? "border border-blue-500"
              : ""
          } p-2 rounded-md`}
        >
          <CommonStyles.TooltipUI
            color="black"
            title={
              <>
                <div>Vĩ độ: {item?.latitude}</div>
                <div>Kinh độ: {item?.longitude}</div>
              </>
            }
          >
            Điểm {index + 1}
          </CommonStyles.TooltipUI>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col gap-2 cursor-pointer">
      {renderFlightPlan()}
      <div className="my-3">
        <div className="text-base mb-2">Danh sách các điểm bay</div>

        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <CommonStyles.InputUI
              value={valueDistance?.start?.point}
              label="Điểm bắt đầu"
              allowClear
              onChange={() => {
                setValueDistance((prev: any) => ({
                  ...prev,
                  start: {
                    point: "",
                    data: "",
                  },
                }));
              }}
            />
            <div className="flex items-center mt-5">
              <CommonIcons.SwapOutlined />
            </div>
            <CommonStyles.InputUI
              value={valueDistance?.end?.point}
              label="Điểm kết thúc"
              allowClear
              onChange={() => {
                setValueDistance((prev: any) => ({
                  ...prev,
                  end: {
                    point: "",
                    data: "",
                  },
                }));
              }}
            />
          </div>

          <div className="my-2 flex flex-col gap-2">
            <CommonStyles.ButtonUI
              onClick={handleCalculateDistance}
              className="w-full"
              disabled={
                !valueDistance?.start?.data || !valueDistance?.end?.data
              }
            >
              Tra khoảng cách
            </CommonStyles.ButtonUI>

            <div
              className={`${
                !!distance &&
                (!!valueDistance?.start?.data || !!valueDistance?.end?.data)
                  ? "visible font-bold"
                  : "invisible"
              }`}
            >
              Khoảng cách: {distance} km
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 my-5 ">
          {renderLocationFlight()}
        </div>
      </div>
    </div>
  );
};
