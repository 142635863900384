import { API_CONFIG_TOP } from "../constants/api";
import { parseQueryString } from "../helpers";
import httpService from "./httpService";

class ConfigTopService {
  getConfigTop(query?: any) {
    return httpService.get(`${API_CONFIG_TOP}?${parseQueryString(query)}`);
  }
  postConfigTop(body: any) {
    return httpService.post(API_CONFIG_TOP, body);
  }

  deleteConfigTop(id: number) {
    return httpService.delete(`${API_CONFIG_TOP}/${id}`);
  }
  updateConfigTop(id: number, body: any) {
    return httpService.patch(`${API_CONFIG_TOP}/${id}`, body);
  }
}

export default new ConfigTopService();
