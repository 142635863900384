import { Routes, Route } from "react-router-dom";
import Maps from "../pages/maps";
import FormTemplate from "../pages/formTemplate";
import DefaultLayout from "../layouts/defaultLayout";
import DetailMap from "../pages/maps/[id]";
import Configs from "../pages/configs";

const Router = () => {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path="/maps" element={<Maps />} />
        <Route path="/maps/:id" element={<DetailMap />} />

        <Route path="/form" element={<FormTemplate />} />

        <Route path="/configs" element={<Configs />} />

        <Route path="/*" element={<Maps />} />
      </Route>

      {/* </Route> */}
    </Routes>
  );
};

export default Router;
