import Router from "./router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { ReactQueryDevtools } from "react-query/devtools";
import { ConfigProvider } from "antd";
import antdTheme from "./themes/AntdTheme";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    },
  },
});
export default function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={antdTheme}>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <Router />
        </ConfigProvider>
      </QueryClientProvider>
    </>
  );
}
