const queryKey = {
  infiniteTemplate: "INFINITE_TEMPLATE",
  infinitePlan: "INFINITE_PLAN",
  infiniteConfigTop: "INFINITE_CONFIG_TOP",
  template: "TEMPLATE",
  plan: "PLAN",
  detailPlan: "DETAIL_PLAN",
  chatFormat: "CHAT_FORMAT",
};

export default queryKey;
