import { useState, useCallback, useEffect } from "react";
import CommonStyles from "../../components/UI";
import FormAdd from "../../components/maps/formAdd";
import SchedulePlan from "../../components/maps/schedule";
import { IPlan } from "../../interfaces/IPlan/IPlan";
import moment from "moment";
import { useGetTemplate } from "../../hooks/useTemplate/useGetInfiniteTemplate";
import { ITemplate } from "../../interfaces/ITemplate/Itemplate";
import _, { isEmpty, uniqueId } from "lodash";
import { IRoute } from "../../interfaces/IChat";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import styles from "./[id]/style.module.css";

export default function Maps() {
  //! state
  const [add, setAdd] = useState(false);
  const [plan, setPlan] = useState<IPlan>({});
  const [itemUpdate, setItemUpdate] = useState<null | IPlan>();

  const { data, isLoading: loadingTemplates } = useGetTemplate({
    items_per_page: 1000,
  });
  const dataTemplate: ITemplate[] = data?.data.data;

  const [polylines, setPolylines] = useState<IRoute[]>([]);

  //! function
  const addAction = () => {
    setAdd(!add);
    setPlan({});
    setItemUpdate(null);
    setPolylines([]);
  };
  const templa: any = _.find(dataTemplate, (item: ITemplate) => {
    return item.id === plan?.template_id;
  });
  const handleUniqueId = useCallback(() => {
    if (!isEmpty(plan)) {
      return uniqueId();
    }
    return "11111";
  }, [plan]);
  useEffect(() => {
    setPolylines([]);
  }, []);
  //! render
  const renderPopupmarkers = useCallback(() => {
    return polylines?.map((item: IRoute, index: number) => {
      const numberIcon = L.divIcon({
        className: `${styles.numberIcon}`,
        iconAnchor: [18, 45],
        iconSize: [35, 60],
        html: `${index + 1}`,
      });
      return (
        <Marker
          position={[Number(item?.latitude), Number(item?.longitude)]}
          key={index}
          icon={numberIcon}
        >
          <Popup className="!p-3" closeButton={false}>
            <div className="flex flex-col">
              <CommonStyles.TypographyUI>
                {`Vĩ độ: ${item?.latitude}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Kinh độ: ${item?.longitude}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Điểm cao nhất: ${item?.top_score}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Thời gian: ${item?.time_at}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Mục tiêu: ${item?.target}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Loại mục tiêu: ${item?.target_type}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Chiều cao mục tiêu: ${item?.target_height}`}
              </CommonStyles.TypographyUI>
            </div>
          </Popup>
        </Marker>
      );
    });
  }, [polylines?.length]);

  const renderStateAdd = useCallback(() => {
    if (add) {
      return (
        <FormAdd
          itemUpdate={itemUpdate}
          handleClose={() => {
            setAdd(!add);
            setPlan({});
            setPolylines([]);
          }}
          setPlan={setPlan}
        />
      );
    }
    return (
      <SchedulePlan
        handleAdd={addAction}
        setPlan={setPlan}
        dataTemplate={dataTemplate}
        setItemUpdate={setItemUpdate}
        itemUpdate={itemUpdate}
        setPolylines={setPolylines}
      />
    );
  }, [add, loadingTemplates]);
  return (
    <div className="grid lg:grid-cols-3 gap-3 h-full md:grid-cols-1">
      {renderStateAdd()}

      <div className="lg:col-span-2 md:col-span-1 mt-2">
        <div className="flex flex-col h-full gap-3">
          <div
            className={
              "w-full border rounded-lg h-full " +
              (_.isEmpty(plan) ? "h-full" : "h-full")
            }
          >
            <CommonStyles.LeafletUI
              polylines={polylines}
              renderPopupMarkers={renderPopupmarkers()}
              dataGeoJson={{
                largeSquare: templa?.large_square,
                basicSquare: templa?.basic_square,
              }}
              optionLayer
              defaultHiddenSquare
              uniqueId={handleUniqueId()}
            />
          </div>

          {!_.isEmpty(plan) && (
            <div className="flex flex-col p-4 border rounded-lg">
              <div className="flex flex-col">
                <CommonStyles.TypographyUI typographyType="title" level={5}>
                  {plan?.title}
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI>{`Mẫu sử dung: Mẫu ${templa?.title}`}</CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI>
                  {`Chỉ số tốp: ${plan.top_score}`}
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI>
                  {`Ngày tạo: ${moment(plan.created_at).format("DD/MM/YYYY")}`}
                </CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI>
                  {`Ghi chú: ${plan.description}`}
                </CommonStyles.TypographyUI>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
