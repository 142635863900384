import { ReactNode } from "react";
import { Input, InputProps, InputRef } from "antd";
interface IProps extends InputProps {
  type?: string;
  maxLength?: number;
  disabled?: boolean;
  size?: "large" | "middle" | "small";
  value?: string | number;
  variant?: "outlined" | "borderless" | "filled";
  onChange?: (e: any) => void;
  onPressEnter?: () => void;
  className?: string;
  placeholder?: string;
  addonAfter?: any;
  label?: string;
  suffix?: ReactNode;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  ref?: any;
}

const InputUI = (props: IProps) => {
  //! state
  const {
    type,
    className,
    placeholder,
    maxLength,
    variant,
    disabled,
    onChange,
    onPressEnter,
    size,
    addonAfter,
    value,
    suffix,
    onKeyUp,
    label,
    ref,
    ...res
  } = props;
  //!! function
  //! render
  return (
    <div>
      {label && <label className="text-sm">{label}</label>}
      <Input
        type={type ? type : "text"}
        value={value}
        maxLength={maxLength}
        className={`h-9 border !border-s-line active:!border-main-color ${className}`}
        placeholder={placeholder}
        variant={variant}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        size={size}
        addonAfter={addonAfter}
        suffix={suffix}
        onKeyUp={onKeyUp}
        ref={ref}
        {...res}
      />
    </div>
  );
};

export default InputUI;
