import { useQuery } from "react-query";
import queryKey from "../../constants/apiKey";
import configTopService from "../../services/configTopService";

export const useGetConfigTop = (filters: any) => {
  return useQuery(
    [queryKey.template],
    () => {
      return configTopService.getConfigTop({
        ...filters,
      });
    },
    { cacheTime: 0, staleTime: 0 }
  );
};
