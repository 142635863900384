import { API_CHAT, urlsAPI } from "../constants/api";
import { parseQueryString } from "../helpers";
import httpService from "./httpService";

class ChatService {
  chat(parmas: any) {
    return httpService.post(`${API_CHAT}?${parseQueryString(parmas)}`);
  }

  chatList(body: any, parmas: any) {
    return httpService.post(
      `${urlsAPI}/chat_list?${parseQueryString(parmas)}`,
      body
    );
  }

  chatFormat() {
    return httpService.get(`${urlsAPI}/chat_format`);
  }
}

export default new ChatService();
