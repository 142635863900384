import L from "leaflet";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { queryClient } from "../../../App";
import CommonIcons from "../../../assets/icons";
import CommonStyles from "../../../components/UI";
import Chatbot from "../../../components/maps/detailMaps/Chatbot";
import queryKey from "../../../constants/apiKey";
import { useHandleNavigate } from "../../../hooks/useHandleNavigate";
import { useGetDetailPlan } from "../../../hooks/usePlan/useGetDetailPlan";
import { IRouteData } from "../../../interfaces/IChat";
import styles from "./style.module.css";
export default function DetailMap() {
  //! state
  // const { handleBack } = useHandleNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const parmas = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const template = location?.state;
  const { data, isLoading } = useGetDetailPlan(Number(parmas.id));
  const [polylines, setPolylines] = useState<IRouteData[]>([]);
  const detailPlan = data?.data?.data;
  const top_score = searchParams.get("top_score");
  //! function
  const handleBack = () => {
    navigate("/maps");
  };
  React.useEffect(() => {
    if (!_.isEmpty(detailPlan)) {
      if (top_score) {
        const routeSelected = detailPlan?.route.find(
          (item: any) => item.top_score === top_score
        );
        setPolylines([routeSelected]);
      }
      if (!top_score) {
        setPolylines([detailPlan?.route[0]]);
        setSearchParams({
          ...searchParams,
          top_score: detailPlan?.route[0]?.top_score,
        });
      }
    }
  }, [isLoading]);

  React.useEffect(() => {
    return () => {
      queryClient.resetQueries({
        queryKey: queryKey.detailPlan,
        exact: true,
      });
    };
  }, []);

  const onChange = (key: string | string[]) => {};

  //! render
  const renderPopupmarkers = useCallback(() => {
    // Lặp qua từng route trong polylines
    return polylines?.map((route: IRouteData, routeIndex: number) => {
      // Lặp qua data_score của mỗi route
      return route?.data_score.map(
        (
          item: {
            latitude?: number;
            longitude?: number;
            top_score?: string;
            time_at?: string;
            target?: string;
            target_type?: string;
            target_height?: string;
          },
          index: number
        ) => {
          const latitude = Number(item?.latitude);
          const longitude = Number(item?.longitude);

          // Tạo icon marker (không có màu ngẫu nhiên)
          const numberIcon = L.divIcon({
            className: `${styles.numberIcon}`,
            iconAnchor: [18, 45],
            iconSize: [35, 60],
            html: `${index + 1}`,
          });

          return (
            <Marker
              position={[latitude, longitude]}
              key={`${routeIndex}-${index}`}
              icon={numberIcon}
            >
              <Popup className="!p-3" closeButton={false}>
                <div className="flex flex-col">
                  <CommonStyles.TypographyUI>{`Vĩ độ: ${latitude.toFixed(
                    6
                  )}`}</CommonStyles.TypographyUI>
                  <CommonStyles.TypographyUI>{`Kinh độ: ${longitude.toFixed(
                    6
                  )}`}</CommonStyles.TypographyUI>
                  <CommonStyles.TypographyUI>{`Chỉ số TOP: ${route.top_score}`}</CommonStyles.TypographyUI>
                  <CommonStyles.TypographyUI>{`Thời gian: ${item?.time_at}`}</CommonStyles.TypographyUI>
                  <CommonStyles.TypographyUI>{`Mục tiêu: ${item?.target}`}</CommonStyles.TypographyUI>
                  <CommonStyles.TypographyUI>{`Loại mục tiêu: ${item?.target_type}`}</CommonStyles.TypographyUI>
                  <CommonStyles.TypographyUI>{`Chiều cao mục tiêu: ${item?.target_height}`}</CommonStyles.TypographyUI>
                </div>
              </Popup>
            </Marker>
          );
        }
      );
    });
  }, [polylines]);

  return (
    <div className="flex h-full w-full">
      <div className="h-full relative w-full">
        <CommonStyles.ButtonUI
          className="bg-white text-black rounded-lg absolute z-1000 top-5 left-5"
          Icons={<CommonIcons.LeftOutlined />}
          size="large"
          onClick={handleBack}
        >
          Trở về
        </CommonStyles.ButtonUI>
        <div className="h-full">
          <CommonStyles.LeafletUI
            polylines={polylines}
            renderPopupMarkers={renderPopupmarkers()}
            optionLayer
            hasWMS
            dataGeoJson={{
              largeSquare: template?.template.large_square,
              basicSquare: template?.template.basic_square,
            }}
          >
            <div className="absolute grid gap-3 z-1000 bottom-5 right-5">
              <Chatbot
                detailPlan={detailPlan}
                template={detailPlan?.template}
                setPolylines={setPolylines}
                polylines={polylines}
              />
            </div>
          </CommonStyles.LeafletUI>
        </div>
      </div>

      {/* <div className="basis-[15%] h-full"></div> */}
    </div>
  );
}
