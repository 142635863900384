import { useState, useCallback } from "react";
import CommonStyles from "../../components/UI";
import CommonIcons from "../../assets/icons";
import { Divider } from "antd";
import FormAddTemplate from "../../components/formTemplate/formAdd";
import moment from "moment";
import useGetInfiniteTemplate, {
  useGetTemplate,
} from "../../hooks/useTemplate/useGetInfiniteTemplate";
import { ITemplate } from "../../interfaces/ITemplate/Itemplate";
import { showError, showSuccess } from "../../helpers/toast";
import { getErrorMsg } from "../../helpers";
import useDeleteTemplate from "../../hooks/useTemplate/useDeleteTemplate";
import { mesSuccess } from "../../constants";
import { uniqueId } from "lodash";
import useToggle from "../../hooks/useToggle";

export default function FormTemplate() {
  //! state
  const { open, shouldRender, toggle } = useToggle();
  const [add, setAdd] = useState(false);
  const [largeSquare, setLargeSquare] = useState<any>();
  const [basicSquare, setBasicSquare] = useState<any>();
  const [itemUpdate, setItemUpdate] = useState<null | ITemplate>(null);
  const filters = {
    items_per_page: 1000,
  };
  const { mutateAsync: deleteTemplate, isLoading: loadingDelete } =
    useDeleteTemplate();
  const {
    data,
    isLoading: loadingTemplates,
    isFetching,
    status,
    refetch: refetchTemplates,
  } = useGetTemplate(filters);
  //! function
  const addAction = () => {
    setAdd(!add);
    setItemUpdate(null);
  };
  const handleCard = (item: ITemplate) => {
    setBasicSquare(item?.basic_square);
    setLargeSquare(item?.large_square);
  };
  const handleUpdate = (item: ITemplate) => {
    setItemUpdate(item);
    setAdd(!add);
  };
  const handleDelete = async (item: ITemplate) => {
    setItemUpdate(item);
    toggle();
  };
  const onConfirmDelete = async () => {
    try {
      await deleteTemplate(Number(itemUpdate?.id), {
        onSuccess: (res) => {
          setItemUpdate(null);
          toggle();
          refetchTemplates();
          showSuccess(res.data?.message ?? mesSuccess);
        },
      });
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  // const listTemplate = data?.data?.data;
  // // const infinityTemplate = data?.pages[0].data.data;
  //! render
  const renderSchedule = useCallback(() => {
    return data?.data?.data?.map((item: ITemplate, index: number) => {
      return (
        <div
          className="flex items-start p-3"
          style={{ background: "rgba(235, 237, 238, 1)" }}
          key={index}
        >
          <div
            className="flex flex-col w-[90%] gap-2 rounded-sm cursor-pointer"
            onClick={() => {
              handleCard(item);
            }}
          >
            <CommonStyles.TypographyUI className="text-lg">
              {item?.title}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI>{`${moment(item?.created_at).format(
              "DD/MM/YYYY"
            )}`}</CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI>{`Duylb`}</CommonStyles.TypographyUI>
          </div>

          <div className="flex justify-end">
            <CommonStyles.ButtomAction
              actionDelete={() => {
                handleDelete(item);
              }}
              actionUpdate={() => {
                handleUpdate(item);
              }}
            />
          </div>
        </div>
      );
    });
  }, [loadingTemplates, isFetching]);

  const renderStateAdd = useCallback(() => {
    if (add) {
      return (
        <FormAddTemplate
          handleClose={() => {
            setAdd(!add);
          }}
          handleBasic={setBasicSquare}
          handleLarge={setLargeSquare}
          itemUpdate={itemUpdate}
          refetchTemplates={refetchTemplates}
        />
      );
    }

    return (
      <div className="col-span-1 border-r  ">
        <div className="flex lg:flex-row lg:justify-between lg:items-center flex-col items-start px-3  mt-3">
          <CommonStyles.TypographyUI className="font-bold text-lg">
            Mẫu mã hoá
          </CommonStyles.TypographyUI>
          <CommonStyles.ButtonUI
            className="bg-black !hover:bg-black text-md "
            Icons={<CommonIcons.PlusOutlined />}
            onClick={addAction}
          >
            Thêm mới
          </CommonStyles.ButtonUI>
        </div>

        <Divider className="m-2" />

        <div
          className="flex flex-col gap-y-2 mx-2 h-[87vh]"
          style={{ overflowY: "scroll" }}
        >
          {renderSchedule()}
        </div>
      </div>
    );
  }, [add, status, isFetching, loadingTemplates]);

  if (loadingTemplates || loadingDelete) {
    return <CommonStyles.LoadingUI />;
  }

  return (
    <div className="grid grid-cols-3 gap-2 h-full">
      {renderStateAdd()}

      <div className="col-span-2 mt-2">
        <div className="flex flex-col h-full gap-3">
          <div className={"w-full border rounded-lg h-full"}>
            <CommonStyles.LeafletUI
              dataGeoJson={{ largeSquare, basicSquare }}
              optionLayer
              uniqueId={uniqueId()}
              defaultAllJSON
            />
          </div>
        </div>
      </div>
      {shouldRender && (
        <CommonStyles.ModalUI
          type="error"
          toggle={toggle}
          open={open}
          onConfirm={onConfirmDelete}
        />
      )}
    </div>
  );
}
