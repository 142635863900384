import React, { useEffect, useState } from "react";
import CommonStyles from "../../UI";
import { redirect, useNavigate } from "react-router-dom";
import CommonIcons from "../../../assets/icons";
import { Divider } from "antd";
import { useGetInfinitePlan } from "../../../hooks/usePlan/useGetPlan";
import moment from "moment";
import { IPlan } from "../../../interfaces/IPlan/IPlan";
import { ITemplate } from "../../../interfaces/ITemplate/Itemplate";
import _ from "lodash";
import { showError, showSuccess } from "../../../helpers/toast";
import { getErrorMsg } from "../../../helpers";
import useDeletePlan from "../../../hooks/usePlan/useDeletePlan";
import { mesSuccess } from "../../../constants";
import useToggle from "../../../hooks/useToggle";
import { useGetDetailPlan } from "../../../hooks/usePlan/useGetDetailPlan";

interface IProps {
  handleAdd: () => void;
  setPlan: any;
  dataTemplate: ITemplate[];
  setItemUpdate: any;
  itemUpdate: any;
  setPolylines: any;
}
export default function SchedulePlan(props: IProps) {
  //! state
  const { handleAdd, setPlan, dataTemplate, setItemUpdate, setPolylines } =
    props;
  const [idDelete, setIdDelete] = useState<null | number>(null);
  const navigate = useNavigate();
  const { open, shouldRender, toggle } = useToggle();
  const filters = {
    items_per_page: 100,
  };
  const {
    data,
    isLoading: loadingPlan,
    refetch: refetchPlan,
  } = useGetInfinitePlan(filters);
  const dataPlane = data?.pages[0].data.data;

  const { mutateAsync: deletePlan, isLoading: loadingDelete } = useDeletePlan();
  const [idPlan, setIdPlan] = useState(NaN);
  const {
    data: detailPlan,
    isLoading,
    refetch,
    isFetching,
  } = useGetDetailPlan(idPlan, {
    enabled: false,
  });
  //! function
  React.useEffect(() => {
    if (!_.isEmpty(detailPlan?.data?.data)) {
      setPolylines(detailPlan?.data.data.route);
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (idPlan) {
      refetch();
    }
  }, [idPlan]);
  const handleDetail = (id: number, plan: IPlan) => {
    const templa: any = _.find(dataTemplate, (item: IPlan) => {
      return item.id === plan?.template_id;
    });

    return navigate(`/maps/${id}`, {
      state: {
        plan: plan,
        template: templa,
      },
    });
  };
  const hanldeClickCard = async (item: IPlan) => {
    setPlan(item);
    setIdPlan(Number(item.id));
  };
  const handleDelete = async (item: IPlan) => {
    setIdDelete(Number(item.id));
    toggle();
  };
  const onConfirmDelete = async () => {
    try {
      await deletePlan(Number(idDelete), {
        onSuccess: (res) => {
          setItemUpdate(null);
          toggle();
          refetchPlan();
          setPlan();
          showSuccess(res.data?.message ?? mesSuccess);
        },
      });
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  const handleUpdate = (item: IPlan) => {
    handleAdd();
    setItemUpdate(item);
    hanldeClickCard(item);
  };
  //! render
  const renderSchedule = () => {
    return dataPlane?.map((item: IPlan, index: number) => {
      return (
        <div
          className="p-3"
          key={index}
          style={{ background: "rgba(235, 237, 238, 1)" }}
        >
          <div className="grid grid-cols-6 ">
            <div
              className="col-span-5 flex flex-col gap-2 rounded-sm cursor-pointer"
              onClick={() => {
                // hanldeClickCard(item);
                handleDetail(Number(item?.id), item);
              }}
            >
              <CommonStyles.TypographyUI className="text-lg">
                {item?.title}
              </CommonStyles.TypographyUI>

              <CommonStyles.TypographyUI>{`Duylb`}</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>{`${moment(item?.created_at).format(
                "DD/MM/YY"
              )}`}</CommonStyles.TypographyUI>
            </div>

            <div className="flex flex-col items-end justify-between">
              <div className="">
                <CommonStyles.ButtomAction
                  actionDelete={() => {
                    handleDelete(item);
                  }}
                  actionUpdate={() => {
                    handleUpdate(item);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <CommonStyles.ButtonUI
              type="text"
              onClick={() => {
                handleDetail(Number(item?.id), item);
              }}
            >
              Chi tiết
            </CommonStyles.ButtonUI>
          </div>
        </div>
      );
    });
  };

  if (loadingPlan || loadingDelete) {
    return <CommonStyles.LoadingUI />;
  }

  return (
    <div className="col-span-1 border-r">
      <div>
        <div className="flex justify-between  px-3 items-center mt-3">
          <CommonStyles.TypographyUI className="font-bold text-lg">
            Lịch trình bay
          </CommonStyles.TypographyUI>
          <CommonStyles.ButtonUI
            className="bg-black !hover:bg-black"
            Icons={<CommonIcons.PlusOutlined />}
            onClick={handleAdd}
          >
            Thêm mới
          </CommonStyles.ButtonUI>
        </div>
        <Divider className="my-2" />
      </div>

      <div
        className="flex flex-col gap-y-2 px-2 "
        style={{ height: "87vh", overflowY: "scroll" }}
      >
        {renderSchedule()}
      </div>
      {shouldRender && (
        <CommonStyles.ModalUI
          type="error"
          toggle={toggle}
          open={open}
          onConfirm={onConfirmDelete}
        />
      )}
    </div>
  );
}
