export const data = "";

export const mesSuccess = "Thành công";

export enum EStatusRecorder {
  Recording = "recording",
  Stopped = "stopped",
  Stopping = "stopping",
  Paused = "paused",
}

export const optionsTopScore = [
  { label: "Máy bay địch (Chiến đấu)", value: "001,190" },
  { label: "Máy bay địch (Huấn luyện)", value: "199,270" },

  { label: "Máy bay từ xa (Chiến đấu)", value: "280,500" },
  { label: "Máy bay từ xa (Huấn luyện)", value: "501,560" },

  { label: "Máy bay quá cảnh (Chiến đấu)", value: "561,780" },
  { label: "Máy bay quá cảnh (Huấn luyện)", value: "781,805" },

  { label: "Máy bay quốc tế (Chiến đấu)", value: "806,970" },
  { label: "Máy bay quốc tế (Huấn luyện)", value: "971,999" },
];
