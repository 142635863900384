import React, { ReactNode } from "react";
import { FloatButton, FloatButtonProps } from "antd";

interface IProps extends FloatButtonProps {
  icon?: ReactNode;
  description?: ReactNode;
  type?: "default" | "primary";
  shape?: "circle" | "square";
  onClick?: () => void;
  tooltip?: ReactNode;
}
export default function FloatButtonUI(props: IProps) {
  //! state
  const { description, icon, onClick, shape, type, tooltip, ...res } = props;
  //! function
  //! render
  return (
    <FloatButton
      description={description}
      icon={icon}
      onClick={onClick}
      shape={shape}
      type={type}
      tooltip={tooltip}
      {...res}
    />
  );
}
